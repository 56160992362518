import Layout from '../components/layout'
import {graphql} from 'gatsby'
import React from 'react'
import Link from 'gatsby-link'
import BodyClassName from 'react-body-classname'
import Img from "gatsby-image"
import '../layouts/artwork.styl'
import PageTransition from 'gatsby-v2-plugin-page-transitions'

export default class CollectionsPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: cosmicknot } = data.cosmicknot
    const { edges: inventors } = data.inventors
    const { edges: geometry } = data.geometry
    const { edges: fables } = data.fables
    return (
      <Layout>
        <PageTransition>
        <BodyClassName className="artwork">
        <div className="page">
        <article className="collections">
        <h2>Cosmic Knot Collection</h2>
        <section className="artwork artworkList" style={{"maxWidth":"1200px","margin":"auto"}}>
        {cosmicknot
          .map(({ node: art }) => (
            <Link to={art.frontmatter.path} key={art.id} >
              <div className="art">
              <Img fluid={art.frontmatter.thumb.childImageSharp.fluid} />
              </div>
            </Link>
        ))}
        </section>
        <h2>Fathers of Invention Collection</h2>
        <section className="artwork artworkList" style={{"maxWidth":"1200px","margin":"auto"}}>
        {inventors
          .map(({ node: art }) => (
            <Link to={art.frontmatter.path} key={art.id} >
              <div className="art">
                <Img fluid={art.frontmatter.thumb.childImageSharp.fluid} />
              </div>
            </Link>
        ))}
        </section>
        <h2>Sacred Geometry Collection</h2>
        <section className="artwork artworkList" style={{"maxWidth":"1200px","margin":"auto"}}>
        {geometry
          .map(({ node: art }) => (
            <Link to={art.frontmatter.path} key={art.id} >
              <div className="art">
                <Img fluid={art.frontmatter.thumb.childImageSharp.fluid} />
              </div>
            </Link>
        ))}
        </section>
        <h2>Fables Collection</h2>
        <section className="artwork artworkList" style={{"maxWidth":"1200px","margin":"auto"}}>
        {fables
          .map(({ node: art }) => (
            <Link to={art.frontmatter.path} key={art.id} >
              <div className="art">
                <Img fluid={art.frontmatter.thumb.childImageSharp.fluid} />            
              </div>
            </Link>
        ))}
        </section>
        </article>
        </div>
        </BodyClassName>
        </PageTransition>
      </Layout>

    )
  }
}

export const CollectionsQuery = graphql`
query CollectionsQuery {
cosmicknot:	allMarkdownRemark(
  limit:9,
  sort: { order: ASC, fields: [frontmatter___collection, frontmatter___order] },
  filter: { frontmatter: { templateKey: { eq: "artwork" }, collection:{eq:"cosmic knot"} }}){
    edges{
      node{
        id
        frontmatter{
          title
          templateKey
          description
          date(formatString: "MMMM DD, YYYY")
          path
          thumb{
            childImageSharp{
              fluid(maxWidth: 500){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
inventors:allMarkdownRemark(
  limit:6,
  sort: { order: ASC, fields: [frontmatter___collection, frontmatter___order] },
  filter: { frontmatter: { templateKey: { eq: "artwork" }, collection:{eq:"inventors"} }}){
    edges{
      node{
        id
        frontmatter{
          title
          templateKey
          description
          date(formatString: "MMMM DD, YYYY")
          path
          thumb{
            childImageSharp{
              fluid(maxWidth: 500){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
geometry:allMarkdownRemark(
  limit:9,
  sort: { order: ASC, fields: [frontmatter___collection, frontmatter___order] },
  filter: { frontmatter: { templateKey: { eq: "artwork" }, collection:{eq:"geometry"} }}){
    edges{
      node{
        id
        frontmatter{
          title
          templateKey
          description
          date(formatString: "MMMM DD, YYYY")
          path
          thumb{
            childImageSharp{
              fluid(maxWidth: 500){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
  fables:allMarkdownRemark(
    limit:9,
    sort: { order: ASC, fields: [frontmatter___collection, frontmatter___order] },
    filter: { frontmatter: { templateKey: { eq: "artwork" }, collection:{eq:"z_misc"} }}){
      edges{
        node{
          id
          frontmatter{
            title
            templateKey
            description
            date(formatString: "MMMM DD, YYYY")
            path
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
}

`
